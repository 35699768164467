const $ = require('jquery');

const Selectize = require('selectize');
require('./selectize/popper-positioning');
// Selectize.defaults.plugins.push('popper_positioning');
// require('bmw_marketing_2019_form_switch/dist/form-switch');

const loading = require('./loading');

const $document = $(document);

const infoEsignFormSelector = '#info-bmwesign-form .form';

function initEvents() {
    $document.on('submitting', infoEsignFormSelector, (event) => {
        loading.setLoadingState();
    }).on('submitted', infoEsignFormSelector, (event) => {
        loading.removeLoadingState();
    }).on('successful-submit', infoEsignFormSelector, (event) => {
        event.def.resolve();
    });
}

function initDealersSelect() {
    $('#dealer').selectize({
        plugins: ['popper_positioning'],
        selectOnTab: true
    });
}

export function init() {
    if (!document.querySelector(infoEsignFormSelector)) {
        return;
    }

    initEvents();

    initDealersSelect();
}
