export function scrollTop() {
    return window.pageYOffset;
}

export function scrollToElement(element, offset = 0) {
    scrollToY(scrollTop() + element.getBoundingClientRect().top + offset);
}

export function scrollToTop() {
    scrollToY(0);
}

export function scrollToY(y) {
    window.scrollTo(0, y);
}
