/**
 * First, we will load all of this project's Javascript utilities and other
 * dependencies. Then, we will be ready to develop a robust and powerful
 * application frontend using useful Laravel and JavaScript libraries.
 */

require('./bootstrap');

require('./modules/form').init();

require('./modules/meeting-card').init();
require('./modules/webex-form').init();

require('./modules/product-card').init();
require('./modules/info-bmwesign-form').init();

require('./modules/book-esign-form').init();
