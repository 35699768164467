const $ = require('jquery');

const Selectize = require('selectize');
require('./selectize/popper-positioning');
// Selectize.defaults.plugins.push('popper_positioning');
// require('bmw_marketing_2019_form_switch/dist/form-switch');

const loading = require('./loading');

const $document = $(document);

const webexFormSelector = '#webex-form .form';

function initEvents() {
    $document.on('submitting', webexFormSelector, (event) => {
        loading.setLoadingState();
    }).on('submitted', webexFormSelector, (event) => {
        loading.removeLoadingState();
    }).on('successful-submit', webexFormSelector, (event) => {
        const { meeting_start_string: meetingStartString } = event.responseData;

        document.querySelector('#webex-meeting-start').textContent = meetingStartString;

        event.def.resolve();
    });
}

function initDealersSelect() {
    $('#dealer').selectize({
        plugins: ['popper_positioning'],
        selectOnTab: true
    });
}

export function init() {
    if (!document.querySelector(webexFormSelector)) {
        return;
    }

    initEvents();

    initDealersSelect();
}
