const $ = require('jquery');

const $document = $(document);

const cardSelector = '.meeting-card';

const activeClassName = 'active';

function initMeetingChange() {
    $document.on('change', 'input[name="meeting_id"]', (event) => {
        $(`${cardSelector}.${activeClassName}`).removeClass(activeClassName);
        $(event.currentTarget).closest(cardSelector).addClass(activeClassName);
    });
}

export function init() {
    initMeetingChange();
}
