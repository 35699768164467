const Selectize = require('selectize');

Selectize.define('popper_positioning', function () {
    const self = this;

    function before(method, fn) {
        const original = self[method];

        self[method] = function () {
            fn.apply(self, arguments);

            return original.apply(self, arguments);
        };
    }

    function after(method, fn) {
        const original = self[method];

        self[method] = function () {
            const result = original.apply(self, arguments);

            fn.apply(self, arguments);

            return result;
        };
    }

    function replace(method, fn) {
        self[method] = function () {
            return fn.apply(self, arguments);
        };
    }

    after('setup', function () {
        self._popper = null;
    });

    replace('positionDropdown', function () {
        self._popper = new Popper(self.$control, self.$dropdown);
    });

    after('refreshOptions', function () {
        if (self._popper) {
            self._popper.scheduleUpdate();
        }
    });

    after('close', function () {
        if (self._popper) {
            self._popper.destroy();
        }
    });

    before('destroy', function () {
        if (self._popper) {
            self._popper.destroy();
        }

        self._popper = null;
    });
});
