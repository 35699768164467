const $ = require('jquery');
const { some, kebabCase, trimEnd } = require('lodash');

const { scrollToElement } = require('./scroll');

const $document = $(document);

const formSelector = '.form';
const submitSelector = '.submit';

const isInvalidClassName = 'is-invalid';
const invalidFeedbackClassName = 'invalid-feedback';

export function isSubmitAllowed($form) {
    return some($form.find(submitSelector), (element) => !element.disabled);
}

function toggleSubmit($form, toggle) {
    $form.find(submitSelector).attr('disabled', !toggle);
}

function allowSubmit($form) {
    toggleSubmit($form, true);
}

function preventSubmit($form) {
    toggleSubmit($form, false);
}

function markError($form, input) {
    const $input = $form.find(`[name="${input}"]`);

    if ($input.hasClass('selectized')) {
        const inputId = $input.attr('id');
        $form.find(`#${inputId}-selectized`).parents('.selectize-control').addClass(isInvalidClassName);

        return;
    }

    const $wrapperLabel = $input.closest('label');

    if ($wrapperLabel.length) {
        $wrapperLabel.addClass(isInvalidClassName);
    } else {
        $input.addClass(isInvalidClassName);
    }
}

function markErrors($form, errors) {
    errors.forEach((name) => {
        markError($form, name);
    });
}

function setError($form, input, message) {
    const $input = $form.find(`[name="${input}"]`);
    const $formGroup = $input.closest('.form-group');

    $input.nextAll(`.${invalidFeedbackClassName}`).remove();
    $formGroup.find(`.${invalidFeedbackClassName}`).remove();

    const invalidFeedback = document.createElement('span');
    invalidFeedback.className = invalidFeedbackClassName;
    invalidFeedback.textContent = message;

    if ($input.hasClass(isInvalidClassName)) {
        $input.after(invalidFeedback);
    } else {
        $formGroup.append(invalidFeedback);
    }
}

function setErrors($form, messages) {
    Object.keys(messages).forEach((name) => {
        setError($form, name, messages[name]);
    });
}

function resetErrors($form) {
    $form.find(`.${isInvalidClassName}`).removeClass(isInvalidClassName);
}

function findFirstError($form) {
    let firstElement;
    let firstTop;

    $form.find(`.${isInvalidClassName}`).filter(':visible').each((i, element) => {
        const currentTop = element.getBoundingClientRect().top;

        if (!firstElement || firstTop > currentTop) {
            firstElement = element;
            firstTop = currentTop;
        }
    });

    return firstElement;
}

function thankYou($form, data, spec) {
    const formSection = $form.closest('.section')[0];

    const specSlug = kebabCase(spec);
    const thankYouSpec = trimEnd(`${formSection.id}-${formSection.id === specSlug ? '' : specSlug}`.replace(/--+/g, '-'), '-');

    const thankYouSection = document.querySelector(`#${thankYouSpec}-thank-you`);

    formSection.style.display = 'none';
    thankYouSection.style.display = 'block';

    scrollToElement(thankYouSection, -40);
}

function submit($form) {
    if (!isSubmitAllowed($form)) {
        return;
    }

    resetErrors($form);

    preventSubmit($form);

    $form.trigger($.Event('submitting'));

    const formData = $form.serialize();

    const def = $.Deferred();

    $.post({
        dataType: 'json',
        data: formData,
        url: $form[0].action
    }).then((data, textStatus, { status }) => {
        def.then((thankYouSpec) => {
            if (status === 201) {
                thankYou($form, data, thankYouSpec);
            }
        });

        $form.trigger($.Event('successful-submit', {
            def,
            status,
            responseData: data
        }));
    }).fail(({ responseJSON: response, status }) => {
        const isClientError = Math.floor(status / 100) === 4;

        if (isClientError) {
            markErrors($form, Object.keys(response.errors));

            if (status !== 422) {
                setErrors($form, response.errors);
            }
        }

        $form.trigger($.Event('unsuccessful-submit', {
            status,
            response
        }));

        if (isClientError) {
            scrollToElement(findFirstError($form), -40);

            allowSubmit($form);
        }
    }).always(() => {
        $form.trigger($.Event('submitted'));
    });
}

function initFormSubmit() {
    $document.on('submit', formSelector, (event) => {
        const currentTarget = event.currentTarget;

        if (!currentTarget.querySelector(submitSelector)) {
            return;
        }

        event.preventDefault();

        submit($(currentTarget));
    }).on('click', submitSelector, (event) => {
        submit($(event.currentTarget.form));
    });
}

export function init() {
    initFormSubmit();
}
